import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _480e9312 = () => interopDefault(import('../shell/pages/about.vue' /* webpackChunkName: "shell/pages/about" */))
const _e6b84c4c = () => interopDefault(import('../shell/pages/account/index.vue' /* webpackChunkName: "shell/pages/account/index" */))
const _0305ebe0 = () => interopDefault(import('../shell/pages/c/index.vue' /* webpackChunkName: "shell/pages/c/index" */))
const _62f81f02 = () => interopDefault(import('../shell/pages/clusters/index.vue' /* webpackChunkName: "shell/pages/clusters/index" */))
const _628a672d = () => interopDefault(import('../shell/pages/diagnostic.vue' /* webpackChunkName: "shell/pages/diagnostic" */))
const _152793c0 = () => interopDefault(import('../shell/pages/fail-whale.vue' /* webpackChunkName: "shell/pages/fail-whale" */))
const _3742e236 = () => interopDefault(import('../shell/pages/home.vue' /* webpackChunkName: "shell/pages/home" */))
const _412e27cc = () => interopDefault(import('../shell/pages/prefs.vue' /* webpackChunkName: "shell/pages/prefs" */))
const _2ba41d54 = () => interopDefault(import('../shell/pages/safeMode.vue' /* webpackChunkName: "shell/pages/safeMode" */))
const _0e8dacfc = () => interopDefault(import('../shell/pages/support/index.vue' /* webpackChunkName: "shell/pages/support/index" */))
const _23ee4036 = () => interopDefault(import('../shell/pages/account/create-key.vue' /* webpackChunkName: "shell/pages/account/create-key" */))
const _2ea30b70 = () => interopDefault(import('../shell/pages/auth/login.vue' /* webpackChunkName: "shell/pages/auth/login" */))
const _7a31d73b = () => interopDefault(import('../shell/pages/auth/logout.vue' /* webpackChunkName: "shell/pages/auth/logout" */))
const _a7edc948 = () => interopDefault(import('../shell/pages/auth/setup.vue' /* webpackChunkName: "shell/pages/auth/setup" */))
const _5a593aaa = () => interopDefault(import('../shell/pages/auth/verify.vue' /* webpackChunkName: "shell/pages/auth/verify" */))
const _590fec55 = () => interopDefault(import('../shell/pages/docs/toc.js' /* webpackChunkName: "shell/pages/docs/toc" */))
const _429c95ec = () => interopDefault(import('../shell/pages/rio/mesh.vue' /* webpackChunkName: "shell/pages/rio/mesh" */))
const _7bbf8530 = () => interopDefault(import('../shell/pages/c/_cluster/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/index" */))
const _be9a6c92 = () => interopDefault(import('../shell/pages/docs/_doc.vue' /* webpackChunkName: "shell/pages/docs/_doc" */))
const _60343407 = () => interopDefault(import('../shell/pages/c/_cluster/apps/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/apps/index" */))
const _7c938886 = () => interopDefault(import('../shell/pages/c/_cluster/auth/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/index" */))
const _a7a2b652 = () => interopDefault(import('../shell/pages/c/_cluster/backup/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/backup/index" */))
const _7935c924 = () => interopDefault(import('../shell/pages/c/_cluster/cis/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/cis/index" */))
const _1635d0a0 = () => interopDefault(import('../shell/pages/c/_cluster/ecm/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/ecm/index" */))
const _6498ced8 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/index" */))
const _543c5da2 = () => interopDefault(import('../shell/pages/c/_cluster/fleet/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/fleet/index" */))
const _183f305c = () => interopDefault(import('../shell/pages/c/_cluster/gatekeeper/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/gatekeeper/index" */))
const _491ced68 = () => interopDefault(import('../shell/pages/c/_cluster/harvesterManager/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/harvesterManager/index" */))
const _40810c91 = () => interopDefault(import('../shell/pages/c/_cluster/istio/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/istio/index" */))
const _6996f3c4 = () => interopDefault(import('../shell/pages/c/_cluster/legacy/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/index" */))
const _0a2a8480 = () => interopDefault(import('../shell/pages/c/_cluster/logging/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/logging/index" */))
const _29b7a358 = () => interopDefault(import('../shell/pages/c/_cluster/longhorn/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/longhorn/index" */))
const _72363f4e = () => interopDefault(import('../shell/pages/c/_cluster/manager/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/index" */))
const _e39926c6 = () => interopDefault(import('../shell/pages/c/_cluster/mcapps/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/mcapps/index" */))
const _e9f5d246 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/index" */))
const _e97dbcfc = () => interopDefault(import('../shell/pages/c/_cluster/neuvector/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/neuvector/index" */))
const _f5832110 = () => interopDefault(import('../shell/pages/c/_cluster/settings/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/index" */))
const _4cece5ed = () => interopDefault(import('../shell/pages/c/_cluster/uiplugins/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/uiplugins/index" */))
const _91828e1a = () => interopDefault(import('../shell/pages/c/_cluster/apps/charts/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/apps/charts/index" */))
const _7191132a = () => interopDefault(import('../shell/pages/c/_cluster/auth/config/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/config/index" */))
const _2e4d06aa = () => interopDefault(import('../shell/pages/c/_cluster/auth/roles/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/roles/index" */))
const _d05f5bba = () => interopDefault(import('../shell/pages/c/_cluster/explorer/ConfigBadge.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/ConfigBadge" */))
const _385a28d7 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/EventsTable.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/EventsTable" */))
const _59b0cd9e = () => interopDefault(import('../shell/pages/c/_cluster/explorer/explorer-utils.js' /* webpackChunkName: "shell/pages/c/_cluster/explorer/explorer-utils" */))
const _22d909e0 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/tools/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/tools/index" */))
const _9ed37f20 = () => interopDefault(import('../shell/pages/c/_cluster/fleet/GitRepoGraphConfig.js' /* webpackChunkName: "shell/pages/c/_cluster/fleet/GitRepoGraphConfig" */))
const _05792b39 = () => interopDefault(import('../shell/pages/c/_cluster/gatekeeper/constraints/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/gatekeeper/constraints/index" */))
const _6e0152b0 = () => interopDefault(import('../shell/pages/c/_cluster/legacy/project/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/project/index" */))
const _e40d6d2a = () => interopDefault(import('../shell/pages/c/_cluster/manager/cloudCredential/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/cloudCredential/index" */))
const _22d1454a = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/alertmanagerconfig/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/alertmanagerconfig/index" */))
const _301a0d68 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/monitor/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/monitor/index" */))
const _ac43f10a = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/route-receiver/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/route-receiver/index" */))
const _a4b6c126 = () => interopDefault(import('../shell/pages/c/_cluster/settings/banners.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/banners" */))
const _f319a0a6 = () => interopDefault(import('../shell/pages/c/_cluster/settings/brand.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/brand" */))
const _7baceb6f = () => interopDefault(import('../shell/pages/c/_cluster/settings/DefaultLinksEditor.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/DefaultLinksEditor" */))
const _559acadf = () => interopDefault(import('../shell/pages/c/_cluster/settings/links.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/links" */))
const _3390f556 = () => interopDefault(import('../shell/pages/c/_cluster/settings/performance.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/performance" */))
const _823747a4 = () => interopDefault(import('../shell/pages/c/_cluster/uiplugins/DeveloperInstallDialog.vue' /* webpackChunkName: "shell/pages/c/_cluster/uiplugins/DeveloperInstallDialog" */))
const _2424983e = () => interopDefault(import('../shell/pages/c/_cluster/uiplugins/InstallDialog.vue' /* webpackChunkName: "shell/pages/c/_cluster/uiplugins/InstallDialog" */))
const _9cb66e84 = () => interopDefault(import('../shell/pages/c/_cluster/uiplugins/PluginInfoPanel.vue' /* webpackChunkName: "shell/pages/c/_cluster/uiplugins/PluginInfoPanel" */))
const _60f294a3 = () => interopDefault(import('../shell/pages/c/_cluster/uiplugins/RemoveUIPlugins.vue' /* webpackChunkName: "shell/pages/c/_cluster/uiplugins/RemoveUIPlugins" */))
const _6c4507a4 = () => interopDefault(import('../shell/pages/c/_cluster/uiplugins/SetupUIPlugins.vue' /* webpackChunkName: "shell/pages/c/_cluster/uiplugins/SetupUIPlugins" */))
const _537fe205 = () => interopDefault(import('../shell/pages/c/_cluster/uiplugins/UninstallDialog.vue' /* webpackChunkName: "shell/pages/c/_cluster/uiplugins/UninstallDialog" */))
const _45a101ff = () => interopDefault(import('../shell/pages/c/_cluster/apps/charts/chart.vue' /* webpackChunkName: "shell/pages/c/_cluster/apps/charts/chart" */))
const _5118aafc = () => interopDefault(import('../shell/pages/c/_cluster/apps/charts/install.vue' /* webpackChunkName: "shell/pages/c/_cluster/apps/charts/install" */))
const _493b0d7a = () => interopDefault(import('../shell/pages/c/_cluster/auth/group.principal/assign-edit.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/group.principal/assign-edit" */))
const _e6f45d32 = () => interopDefault(import('../shell/pages/c/_cluster/legacy/project/pipelines.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/project/pipelines" */))
const _58e42eb3 = () => interopDefault(import('../shell/pages/c/_cluster/manager/cloudCredential/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/cloudCredential/create" */))
const _0a482b54 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/monitor/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/monitor/create" */))
const _8cd19aba = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/route-receiver/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/route-receiver/create" */))
const _cc03a51e = () => interopDefault(import('../shell/pages/c/_cluster/explorer/tools/pages/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/tools/pages/_page" */))
const _8c844fdc = () => interopDefault(import('../shell/pages/c/_cluster/auth/config/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/config/_id" */))
const _b2e1f9a2 = () => interopDefault(import('../shell/pages/c/_cluster/legacy/pages/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/pages/_page" */))
const _31c09b44 = () => interopDefault(import('../shell/pages/c/_cluster/legacy/project/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/project/_page" */))
const _00deaada = () => interopDefault(import('../shell/pages/c/_cluster/manager/cloudCredential/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/cloudCredential/_id" */))
const _d665cf42 = () => interopDefault(import('../shell/pages/c/_cluster/manager/pages/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/pages/_page" */))
const _6afe6324 = () => interopDefault(import('../shell/pages/c/_cluster/mcapps/pages/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/mcapps/pages/_page" */))
const _68006399 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/alertmanagerconfig/_alertmanagerconfigid/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/alertmanagerconfig/_alertmanagerconfigid/index" */))
const _6eadaca3 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/route-receiver/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/route-receiver/_id" */))
const _2611fe95 = () => interopDefault(import('../shell/pages/c/_cluster/auth/roles/_resource/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/roles/_resource/create" */))
const _f106ed10 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/alertmanagerconfig/_alertmanagerconfigid/receiver.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/alertmanagerconfig/_alertmanagerconfigid/receiver" */))
const _2324941e = () => interopDefault(import('../shell/pages/c/_cluster/auth/roles/_resource/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/roles/_resource/_id" */))
const _7af93ae1 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/monitor/_namespace/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/monitor/_namespace/_id" */))
const _7fb91697 = () => interopDefault(import('../shell/pages/c/_cluster/navlinks/_group.vue' /* webpackChunkName: "shell/pages/c/_cluster/navlinks/_group" */))
const _a1d24a76 = () => interopDefault(import('../shell/pages/c/_cluster/_product/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/index" */))
const _e529db62 = () => interopDefault(import('../shell/pages/c/_cluster/_product/members/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/members/index" */))
const _6e305595 = () => interopDefault(import('../shell/pages/c/_cluster/_product/namespaces.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/namespaces" */))
const _b79488e2 = () => interopDefault(import('../shell/pages/c/_cluster/_product/projectsnamespaces.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/projectsnamespaces" */))
const _309afae3 = () => interopDefault(import('../shell/pages/c/_cluster/_product/_resource/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/_resource/index" */))
const _0a78ac3b = () => interopDefault(import('../shell/pages/c/_cluster/_product/_resource/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/_resource/create" */))
const _57605a0b = () => interopDefault(import('../shell/pages/c/_cluster/_product/_resource/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/_resource/_id" */))
const _677c47c6 = () => interopDefault(import('../shell/pages/c/_cluster/_product/_resource/_namespace/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/_resource/_namespace/_id" */))
const _089edd88 = () => interopDefault(import('../shell/pages/index.vue' /* webpackChunkName: "shell/pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/dashboard/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _480e9312,
    name: "about"
  }, {
    path: "/account",
    component: _e6b84c4c,
    name: "account"
  }, {
    path: "/c",
    component: _0305ebe0,
    name: "c"
  }, {
    path: "/clusters",
    component: _62f81f02,
    name: "clusters"
  }, {
    path: "/diagnostic",
    component: _628a672d,
    name: "diagnostic"
  }, {
    path: "/fail-whale",
    component: _152793c0,
    name: "fail-whale"
  }, {
    path: "/home",
    component: _3742e236,
    name: "home"
  }, {
    path: "/prefs",
    component: _412e27cc,
    name: "prefs"
  }, {
    path: "/safeMode",
    component: _2ba41d54,
    name: "safeMode"
  }, {
    path: "/support",
    component: _0e8dacfc,
    name: "support"
  }, {
    path: "/account/create-key",
    component: _23ee4036,
    name: "account-create-key"
  }, {
    path: "/auth/login",
    component: _2ea30b70,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _7a31d73b,
    name: "auth-logout"
  }, {
    path: "/auth/setup",
    component: _a7edc948,
    name: "auth-setup"
  }, {
    path: "/auth/verify",
    component: _5a593aaa,
    name: "auth-verify"
  }, {
    path: "/docs/toc",
    component: _590fec55,
    name: "docs-toc"
  }, {
    path: "/rio/mesh",
    component: _429c95ec,
    name: "rio-mesh"
  }, {
    path: "/c/:cluster",
    component: _7bbf8530,
    name: "c-cluster"
  }, {
    path: "/docs/:doc?",
    component: _be9a6c92,
    name: "docs-doc"
  }, {
    path: "/c/:cluster/apps",
    component: _60343407,
    name: "c-cluster-apps"
  }, {
    path: "/c/:cluster/auth",
    component: _7c938886,
    name: "c-cluster-auth"
  }, {
    path: "/c/:cluster/backup",
    component: _a7a2b652,
    name: "c-cluster-backup"
  }, {
    path: "/c/:cluster/cis",
    component: _7935c924,
    name: "c-cluster-cis"
  }, {
    path: "/c/:cluster/ecm",
    component: _1635d0a0,
    name: "c-cluster-ecm"
  }, {
    path: "/c/:cluster/explorer",
    component: _6498ced8,
    name: "c-cluster-explorer"
  }, {
    path: "/c/:cluster/fleet",
    component: _543c5da2,
    name: "c-cluster-fleet"
  }, {
    path: "/c/:cluster/gatekeeper",
    component: _183f305c,
    name: "c-cluster-gatekeeper"
  }, {
    path: "/c/:cluster/harvesterManager",
    component: _491ced68,
    name: "c-cluster-harvesterManager"
  }, {
    path: "/c/:cluster/istio",
    component: _40810c91,
    name: "c-cluster-istio"
  }, {
    path: "/c/:cluster/legacy",
    component: _6996f3c4,
    name: "c-cluster-legacy"
  }, {
    path: "/c/:cluster/logging",
    component: _0a2a8480,
    name: "c-cluster-logging"
  }, {
    path: "/c/:cluster/longhorn",
    component: _29b7a358,
    name: "c-cluster-longhorn"
  }, {
    path: "/c/:cluster/manager",
    component: _72363f4e,
    name: "c-cluster-manager"
  }, {
    path: "/c/:cluster/mcapps",
    component: _e39926c6,
    name: "c-cluster-mcapps"
  }, {
    path: "/c/:cluster/monitoring",
    component: _e9f5d246,
    name: "c-cluster-monitoring"
  }, {
    path: "/c/:cluster/neuvector",
    component: _e97dbcfc,
    name: "c-cluster-neuvector"
  }, {
    path: "/c/:cluster/settings",
    component: _f5832110,
    name: "c-cluster-settings"
  }, {
    path: "/c/:cluster/uiplugins",
    component: _4cece5ed,
    name: "c-cluster-uiplugins"
  }, {
    path: "/c/:cluster/apps/charts",
    component: _91828e1a,
    name: "c-cluster-apps-charts"
  }, {
    path: "/c/:cluster/auth/config",
    component: _7191132a,
    name: "c-cluster-auth-config"
  }, {
    path: "/c/:cluster/auth/roles",
    component: _2e4d06aa,
    name: "c-cluster-auth-roles"
  }, {
    path: "/c/:cluster/explorer/ConfigBadge",
    component: _d05f5bba,
    name: "c-cluster-explorer-ConfigBadge"
  }, {
    path: "/c/:cluster/explorer/EventsTable",
    component: _385a28d7,
    name: "c-cluster-explorer-EventsTable"
  }, {
    path: "/c/:cluster/explorer/explorer-utils",
    component: _59b0cd9e,
    name: "c-cluster-explorer-explorer-utils"
  }, {
    path: "/c/:cluster/explorer/tools",
    component: _22d909e0,
    name: "c-cluster-explorer-tools"
  }, {
    path: "/c/:cluster/fleet/GitRepoGraphConfig",
    component: _9ed37f20,
    name: "c-cluster-fleet-GitRepoGraphConfig"
  }, {
    path: "/c/:cluster/gatekeeper/constraints",
    component: _05792b39,
    name: "c-cluster-gatekeeper-constraints"
  }, {
    path: "/c/:cluster/legacy/project",
    component: _6e0152b0,
    name: "c-cluster-legacy-project"
  }, {
    path: "/c/:cluster/manager/cloudCredential",
    component: _e40d6d2a,
    name: "c-cluster-manager-cloudCredential"
  }, {
    path: "/c/:cluster/monitoring/alertmanagerconfig",
    component: _22d1454a,
    name: "c-cluster-monitoring-alertmanagerconfig"
  }, {
    path: "/c/:cluster/monitoring/monitor",
    component: _301a0d68,
    name: "c-cluster-monitoring-monitor"
  }, {
    path: "/c/:cluster/monitoring/route-receiver",
    component: _ac43f10a,
    name: "c-cluster-monitoring-route-receiver"
  }, {
    path: "/c/:cluster/settings/banners",
    component: _a4b6c126,
    name: "c-cluster-settings-banners"
  }, {
    path: "/c/:cluster/settings/brand",
    component: _f319a0a6,
    name: "c-cluster-settings-brand"
  }, {
    path: "/c/:cluster/settings/DefaultLinksEditor",
    component: _7baceb6f,
    name: "c-cluster-settings-DefaultLinksEditor"
  }, {
    path: "/c/:cluster/settings/links",
    component: _559acadf,
    name: "c-cluster-settings-links"
  }, {
    path: "/c/:cluster/settings/performance",
    component: _3390f556,
    name: "c-cluster-settings-performance"
  }, {
    path: "/c/:cluster/uiplugins/DeveloperInstallDialog",
    component: _823747a4,
    name: "c-cluster-uiplugins-DeveloperInstallDialog"
  }, {
    path: "/c/:cluster/uiplugins/InstallDialog",
    component: _2424983e,
    name: "c-cluster-uiplugins-InstallDialog"
  }, {
    path: "/c/:cluster/uiplugins/PluginInfoPanel",
    component: _9cb66e84,
    name: "c-cluster-uiplugins-PluginInfoPanel"
  }, {
    path: "/c/:cluster/uiplugins/RemoveUIPlugins",
    component: _60f294a3,
    name: "c-cluster-uiplugins-RemoveUIPlugins"
  }, {
    path: "/c/:cluster/uiplugins/SetupUIPlugins",
    component: _6c4507a4,
    name: "c-cluster-uiplugins-SetupUIPlugins"
  }, {
    path: "/c/:cluster/uiplugins/UninstallDialog",
    component: _537fe205,
    name: "c-cluster-uiplugins-UninstallDialog"
  }, {
    path: "/c/:cluster/apps/charts/chart",
    component: _45a101ff,
    name: "c-cluster-apps-charts-chart"
  }, {
    path: "/c/:cluster/apps/charts/install",
    component: _5118aafc,
    name: "c-cluster-apps-charts-install"
  }, {
    path: "/c/:cluster/auth/group.principal/assign-edit",
    component: _493b0d7a,
    name: "c-cluster-auth-group.principal-assign-edit"
  }, {
    path: "/c/:cluster/legacy/project/pipelines",
    component: _e6f45d32,
    name: "c-cluster-legacy-project-pipelines"
  }, {
    path: "/c/:cluster/manager/cloudCredential/create",
    component: _58e42eb3,
    name: "c-cluster-manager-cloudCredential-create"
  }, {
    path: "/c/:cluster/monitoring/monitor/create",
    component: _0a482b54,
    name: "c-cluster-monitoring-monitor-create"
  }, {
    path: "/c/:cluster/monitoring/route-receiver/create",
    component: _8cd19aba,
    name: "c-cluster-monitoring-route-receiver-create"
  }, {
    path: "/c/:cluster/explorer/tools/pages/:page?",
    component: _cc03a51e,
    name: "c-cluster-explorer-tools-pages-page"
  }, {
    path: "/c/:cluster/auth/config/:id",
    component: _8c844fdc,
    name: "c-cluster-auth-config-id"
  }, {
    path: "/c/:cluster/legacy/pages/:page?",
    component: _b2e1f9a2,
    name: "c-cluster-legacy-pages-page"
  }, {
    path: "/c/:cluster/legacy/project/:page",
    component: _31c09b44,
    name: "c-cluster-legacy-project-page"
  }, {
    path: "/c/:cluster/manager/cloudCredential/:id",
    component: _00deaada,
    name: "c-cluster-manager-cloudCredential-id"
  }, {
    path: "/c/:cluster/manager/pages/:page?",
    component: _d665cf42,
    name: "c-cluster-manager-pages-page"
  }, {
    path: "/c/:cluster/mcapps/pages/:page?",
    component: _6afe6324,
    name: "c-cluster-mcapps-pages-page"
  }, {
    path: "/c/:cluster/monitoring/alertmanagerconfig/:alertmanagerconfigid",
    component: _68006399,
    name: "c-cluster-monitoring-alertmanagerconfig-alertmanagerconfigid"
  }, {
    path: "/c/:cluster/monitoring/route-receiver/:id?",
    component: _6eadaca3,
    name: "c-cluster-monitoring-route-receiver-id"
  }, {
    path: "/c/:cluster/auth/roles/:resource/create",
    component: _2611fe95,
    name: "c-cluster-auth-roles-resource-create"
  }, {
    path: "/c/:cluster/monitoring/alertmanagerconfig/:alertmanagerconfigid/receiver",
    component: _f106ed10,
    name: "c-cluster-monitoring-alertmanagerconfig-alertmanagerconfigid-receiver"
  }, {
    path: "/c/:cluster/auth/roles/:resource/:id?",
    component: _2324941e,
    name: "c-cluster-auth-roles-resource-id"
  }, {
    path: "/c/:cluster/monitoring/monitor/:namespace/:id?",
    component: _7af93ae1,
    name: "c-cluster-monitoring-monitor-namespace-id"
  }, {
    path: "/c/:cluster/navlinks/:group?",
    component: _7fb91697,
    name: "c-cluster-navlinks-group"
  }, {
    path: "/c/:cluster/:product",
    component: _a1d24a76,
    name: "c-cluster-product"
  }, {
    path: "/c/:cluster/:product/members",
    component: _e529db62,
    name: "c-cluster-product-members"
  }, {
    path: "/c/:cluster/:product/namespaces",
    component: _6e305595,
    name: "c-cluster-product-namespaces"
  }, {
    path: "/c/:cluster/:product/projectsnamespaces",
    component: _b79488e2,
    name: "c-cluster-product-projectsnamespaces"
  }, {
    path: "/c/:cluster/:product/:resource",
    component: _309afae3,
    name: "c-cluster-product-resource"
  }, {
    path: "/c/:cluster/:product/:resource/create",
    component: _0a78ac3b,
    name: "c-cluster-product-resource-create"
  }, {
    path: "/c/:cluster/:product/:resource/:id",
    component: _57605a0b,
    name: "c-cluster-product-resource-id"
  }, {
    path: "/c/:cluster/:product/:resource/:namespace/:id?",
    component: _677c47c6,
    name: "c-cluster-product-resource-namespace-id"
  }, {
    path: "/",
    component: _089edd88,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
